import React, { useEffect } from 'react';

const CalendlyScheduler = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="bg-gray-100 py-16 mt-20">
      <div className="container mx-auto px-4 max-w-2xl text-center">
        <h2 className="text-3xl font-bold mb-4 text-gray-800">Talk to an Expert</h2>
        <p className="text-lg mb-8 text-gray-600">
          Schedule a call with one of our experts to discuss how we can help your business.
        </p>
        <div className="bg-white shadow-md rounded p-6">
          <div 
            className="calendly-inline-widget" 
            data-url="https://calendly.com/gopal-jaanch/30min" 
            style={{ minWidth: '320px', height: '630px' }} 
          />
        </div>
      </div>
    </section>
  );
};

export default CalendlyScheduler;