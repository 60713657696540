import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { FaBullhorn, FaArrowCircleRight, FaChartLine, FaRocket } from 'react-icons/fa';
import DashboardLayout from './DashboardLayout';
import Modal from './Modal';

const MarketingCampaigns = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const campaignPerformance = {
    labels: ['Campaign A', 'Campaign B', 'Campaign C', 'Campaign D', 'Campaign E'],
    datasets: [
      {
        label: 'Conversion Rate (%)',
        data: [3.5, 4.2, 5.1, 2.9, 6.3],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(255, 99, 132, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ],
      },
    ],
  };

  const roiData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'ROI (%)',
        data: [120, 135, 150, 130, 160, 175],
        fill: false,
        borderColor: '#FF6384',
        tension: 0.1,
      },
    ],
  };

  const renderLaunchCampaign = () => (
    <div>
      <p className="mb-4">Launch a new marketing campaign:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Campaign Name</label>
          <input type="text" className="w-full border-gray-300 rounded-md" placeholder="Enter campaign name" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Target Audience</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>18-25</option>
            <option>26-35</option>
            <option>36-45</option>
            <option>45+</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Budget ($)</label>
          <input type="number" className="w-full border-gray-300 rounded-md" placeholder="Enter budget" />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Launch Campaign
        </button>
      </form>
    </div>
  );

  const renderOptimizeCampaign = () => (
    <div>
      <p className="mb-4">Optimize an existing campaign to improve performance:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Adjust the target audience for better engagement.</li>
        <li>Reallocate budget towards higher-performing channels.</li>
        <li>Refine the messaging to increase conversion rates.</li>
      </ul>
      <button type="button" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
        Optimize Campaign
      </button>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
          <FaBullhorn className="text-purple-500 mr-2" /> Marketing Campaigns
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-yellow-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaChartLine className="text-yellow-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-yellow-800">ROI Over Time</p>
              <p className="text-2xl font-bold text-yellow-900">{roiData.datasets[0].data.slice(-1)[0]}%</p>
              <button
                onClick={() => handleModalOpen('Optimize Campaign', renderOptimizeCampaign())}
                className="ml-2 mt-2 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
              >
                Optimize Campaign <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>

          <div className="bg-green-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaRocket className="text-green-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-green-800">Launch New Campaign</p>
              <button
                onClick={() => handleModalOpen('Launch Campaign', renderLaunchCampaign())}
                className="ml-2 mt-2 inline-block bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
              >
                Launch Campaign <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>
        </div>

        <div className="bg-gray-100 p-6 rounded-lg shadow-inner mb-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Conversion Rate by Campaign</h3>
          <Bar data={campaignPerformance} />
        </div>

        <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">ROI Over Time</h3>
          <Line data={roiData} />
        </div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default MarketingCampaigns;
