import React, { useEffect } from 'react';
import { FaChartLine, FaUsers, FaPercentage, FaDollarSign, FaBoxes } from 'react-icons/fa';
import { motion, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const JaanchAIOverview = () => {
  const controls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      await controls.start({ opacity: 1, y: 0, transition: { duration: 0.8 } });
    };

    sequence();
  }, [controls]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const caseStudies = [
    {
      icon: <FaChartLine className="text-3xl text-blue-500 mr-4" />,
      title: "Boosting Electronics Sales",
      details: (
        <ul className="list-disc pl-6 text-gray-300">
          <li className="mb-2"><strong>Issue:</strong> A 20% decline in sales for high-end electronics has been identified.</li>
          <li className="mb-2"><strong>Action Plan:</strong> Introduce a limited-time discount on 15 premium products to stimulate demand and attract customers.</li>
          <li><strong>Expected Outcome:</strong> Anticipated 12% increase in sales within the next quarter.</li>
        </ul>
      ),
    },
    {
      icon: <FaUsers className="text-3xl text-blue-500 mr-4" />,
      title: "Reducing High-Value Customer Churn",
      details: (
        <ul className="list-disc pl-6 text-gray-300">
          <li className="mb-2"><strong>Risk:</strong> 9,500 high-value customers show a 95% likelihood of churning in the next 60 days.</li>
          <li className="mb-2"><strong>Potential Revenue Loss:</strong> Estimated annual revenue loss of $3 million if churn is not addressed.</li>
          <li><strong>Action Plan:</strong> Implement personalized retention campaigns and loyalty programs targeting these customers.</li>
        </ul>
      ),
    },
    {
      icon: <FaPercentage className="text-3xl text-blue-500 mr-4" />,
      title: "Maximizing Seasonal Promotions",
      details: (
        <ul className="list-disc pl-6 text-gray-300">
          <li className="mb-2"><strong>Opportunity:</strong> 35 seasonal products have potential for significant promotional impact.</li>
          <li className="mb-2"><strong>Action Plan:</strong> Strategically discount these items and enhance marketing efforts during peak seasons.</li>
          <li><strong>Expected Outcome:</strong> Increase in conversion rates by 18%, clearing seasonal inventory efficiently.</li>
        </ul>
      ),
    },
    {
      icon: <FaDollarSign className="text-3xl text-blue-500 mr-4" />,
      title: "Strategic Price Increases",
      details: (
        <ul className="list-disc pl-6 text-gray-300">
          <li className="mb-2"><strong>Action Needed:</strong> Price adjustments on 22 high-demand products.</li>
          <li className="mb-2"><strong>Strategy:</strong> Increase prices by 8% based on competitor analysis and demand forecasting.</li>
          <li><strong>Expected Profit Increase:</strong> Projected 25% increase in quarterly profits from these adjustments.</li>
        </ul>
      ),
    },
    {
      icon: <FaBoxes className="text-3xl text-blue-500 mr-4" />,
      title: "Inventory Optimization",
      details: (
        <ul className="list-disc pl-6 text-gray-300">
          <li className="mb-2"><strong>Issue:</strong> 150 top-selling items are understocked and at risk of running out within 4 weeks.</li>
          <li className="mb-2"><strong>Risk:</strong> Potential loss of $75,000 in sales due to stockouts.</li>
          <li className="mb-2"><strong>Action Plan:</strong> Prioritize restocking and optimize inventory levels using predictive analytics to meet customer demand.</li>
          <li><strong>Expected Outcome:</strong> Reduced stockouts and maintained sales momentum.</li>
        </ul>
      ),
    },
  ];

  return (
    <section className="bg-gray-900 py-16">
      <motion.div
        className="container mx-auto px-4"
        initial={{ opacity: 0, y: 20 }}
        animate={controls}
      >
        <motion.h2 className="text-4xl font-bold mb-8 text-center text-white">
          JaanchGPT: Transforming E-commerce with Intelligent Insights
        </motion.h2>
        <motion.div className="mb-12">
          <h3 className="text-2xl font-bold text-blue-500 mb-4">Overview</h3>
          <p className="text-gray-300">
            <strong>JaanchGPT</strong> is a sophisticated AI-driven platform designed to integrate multiple e-commerce data sources, delivering detailed, actionable insights to optimize sales, pricing, promotions, and inventory management.
          </p>
        </motion.div>
        <motion.div className="mb-12">
          <h3 className="text-2xl font-bold text-blue-500 mb-4">Connected Systems</h3>
          <ul className="list-disc pl-6 text-gray-300">
            <motion.li className="mb-2" initial={{ opacity: 0, x: -20 }} animate={controls}>
              <strong>ERP</strong> (Enterprise Resource Planning) for comprehensive business process management.
            </motion.li>
            <motion.li className="mb-2" initial={{ opacity: 0, x: -20 }} animate={controls}>
              <strong>Point of Sale</strong> systems to capture real-time sales data.
            </motion.li>
            <motion.li className="mb-2" initial={{ opacity: 0, x: -20 }} animate={controls}>
              <strong>E-commerce Platforms</strong> (e.g., Shopify, Magento) for online sales tracking.
            </motion.li>
            <motion.li className="mb-2" initial={{ opacity: 0, x: -20 }} animate={controls}>
              <strong>Marketplaces</strong> (e.g., Amazon, eBay) for broader market reach and sales integration.
            </motion.li>
            <motion.li className="mb-2" initial={{ opacity: 0, x: -20 }} animate={controls}>
              <strong>SFTP</strong> (Secure File Transfer Protocol) for secure data exchange.
            </motion.li>
            <motion.li initial={{ opacity: 0, x: -20 }} animate={controls}>
              <strong>Google Analytics</strong> for detailed web and marketing analytics.
            </motion.li>
          </ul>
        </motion.div>
        <motion.div className="mb-12">
          <h3 className="text-2xl font-bold text-blue-500 mb-4">Case Study: Driving Growth with JaanchGPT</h3>
          <Slider {...settings}>
            {caseStudies.map((study, index) => (
              <div key={index} className="bg-gray-800 rounded-lg shadow-lg p-6">
                <div className="flex items-center mb-4">
                  {study.icon}
                  <h4 className="text-xl font-bold text-white">{study.title}</h4>
                </div>
                {study.details}
              </div>
            ))}
          </Slider>
        </motion.div>
        <motion.div initial={{ opacity: 0, y: 20 }} animate={controls}>
          <h3 className="text-2xl font-bold text-blue-500 mb-4">Impact</h3>
          <p className="text-gray-300">
            By leveraging JaanchGPT's comprehensive insights, the company can make data-driven decisions to enhance product sales, optimize inventory, and drive overall profitability. The intelligent integration of diverse data sources ensures real-time, actionable insights that align with business goals and customer needs.
          </p>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default JaanchAIOverview;
