import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { FaArrowUp, FaArrowDown, FaDollarSign, FaChartLine, FaArrowCircleRight } from 'react-icons/fa';
import DashboardLayout from './DashboardLayout';
import Modal from './Modal';

const SalesAnalytics = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const salesData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sales',
        data: [12000, 15000, 17000, 22000, 25000, 27000],
        fill: false,
        borderColor: '#4CAF50',
        tension: 0.1,
      },
    ],
  };

  const salesGrowth = 12.5;
  const topProduct = 'Smartphone Pro Max';

  const renderGrowthStrategy = () => (
    <div>
      <p className="mb-4">To sustain the growth rate of {salesGrowth}%, consider the following strategies:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Increase marketing spend on high-performing channels.</li>
        <li>Expand the top product's availability in more regions.</li>
        <li>Introduce loyalty programs for frequent buyers.</li>
      </ul>
      <button type="button" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
        Implement Strategy
      </button>
    </div>
  );

  const renderTopProductCampaign = () => (
    <div>
      <p className="mb-4">Leverage the success of the {topProduct} by launching a new marketing campaign:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Select Region</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>North America</option>
            <option>Europe</option>
            <option>Asia-Pacific</option>
          </select>
        </div>
        <button type="submit" className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">
          Launch Campaign
        </button>
      </form>
    </div>
  );

  const renderSalesDropAction = () => (
    <div>
      <p className="mb-4">Address the sales drop in the Southwest region:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Conduct a market analysis to understand the decline.</li>
        <li>Offer region-specific discounts and promotions.</li>
        <li>Engage local influencers to boost brand awareness.</li>
      </ul>
      <button type="button" className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
        Execute Plan
      </button>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
          <FaChartLine className="text-green-500 mr-2" /> Sales Analytics
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-blue-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaDollarSign className="text-blue-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-blue-800">Total Sales</p>
              <p className="text-2xl font-bold text-blue-900">$27,000</p>
            </div>
          </div>

          <div className="bg-green-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaArrowUp className="text-green-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-green-800">Growth Rate</p>
              <p className="text-2xl font-bold text-green-900">{salesGrowth}%</p>
              <button
                onClick={() => handleModalOpen('Sustain Growth Strategy', renderGrowthStrategy())}
                className="ml-2 mt-2 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
              >
                Sustain Growth <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>

          <div className="bg-yellow-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaChartLine className="text-yellow-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-yellow-800">Top Product</p>
              <p className="text-2xl font-bold text-yellow-900">{topProduct}</p>
              <button
                onClick={() => handleModalOpen('Top Product Campaign', renderTopProductCampaign())}
                className="ml-2 mt-2 inline-block bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
              >
                Promote Product <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>

          <div className="bg-red-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaArrowDown className="text-red-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-red-800">Sales Drop (Region)</p>
              <p className="text-2xl font-bold text-red-900">-8% (Southwest)</p>
              <button
                onClick={() => handleModalOpen('Address Sales Drop', renderSalesDropAction())}
                className="ml-2 mt-2 inline-block bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                Address Drop <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>
        </div>

        <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Sales Over Time</h3>
          <Line data={salesData} />
        </div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default SalesAnalytics;
