// withHeader.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const withHeader = (WrappedComponent) => {
  const WithHeader = (props) => {
    return (
      <>
        <Header />
        <WrappedComponent {...props} />
        <Footer />
      </>
    );
  };

  return WithHeader;
};

export default withHeader;