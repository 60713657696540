import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaHome } from 'react-icons/fa';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-gray-900 text-white fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 py-6 flex items-center justify-between">
        <div className="flex items-center">
          <Link to="/" className="text-3xl font-bold tracking-wider mr-6">
            <span className="text-blue-500">Jaanch</span>
            <span className="text-white">AI</span>
          </Link>
        </div>
        <div className="hidden md:flex items-center space-x-6">
          <nav>
            <ul className="flex space-x-6 items-center">
              <li>
                <Link to="/" className="text-lg hover:text-blue-500 transition-colors duration-300">
                  <FaHome className="inline-block mr-1" /> Home
                </Link>
              </li>
              <li>
                <Link to="/solutions" className="text-lg hover:text-blue-500 transition-colors duration-300">
                  Solutions
                </Link>
              </li>

              {/* faq */}
              <li>
                <Link to="/faq" className="text-lg hover:text-blue-500 transition-colors duration-300">
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  to="/talk-to-expert"
                  className="text-lg hover:text-blue-500 transition-colors duration-300"
                >
                  Talk to an Expert
                </Link>
              </li>
              <li>
                <Link
                  to="/request-demo"
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300"
                >
                  Request a Demo
                </Link>
              </li>
            </ul>
          </nav>
          {/* <a 
            href="https://blog.jaanch.ai" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-lg bg-gradient-to-r from-green-400 to-blue-500 text-white py-2 px-4 rounded-lg shadow-lg transition-transform transform hover:scale-105"
          >
            Blog
          </a> */}
        </div>
        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="text-2xl">
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden bg-gray-900 bg-opacity-90 text-white py-4">
          <nav>
            <ul className="flex flex-col space-y-4 items-center">
              <li>
                {/* <a 
                  href="https://blog.jaanch.ai" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-lg bg-gradient-to-r from-green-400 to-blue-500 text-white py-2 px-4 rounded-lg shadow-lg transition-transform transform hover:scale-105"
                  onClick={toggleMobileMenu}
                >
                  Blog
                </a> */}
              </li>
              <li>
                <Link to="/" className="text-lg hover:text-blue-500 transition-colors duration-300" onClick={toggleMobileMenu}>
                  <FaHome className="inline-block mr-1" /> Home
                </Link>
              </li>
              <li>
                <Link to="/solutions" className="text-lg hover:text-blue-500 transition-colors duration-300" onClick={toggleMobileMenu}>
                  Solutions
                </Link>
              </li>

              {/* faq */}
              <li>
                <Link to="/faq" className="text-lg hover:text-blue-500 transition-colors duration-300" onClick={toggleMobileMenu}>
                  FAQ
                </Link>
              </li>

              <li>
                <Link
                  to="/talk-to-expert"
                  className="text-lg hover:text-blue-500 transition-colors duration-300"
                  onClick={toggleMobileMenu}
                >
                  Talk to an Expert
                </Link>
              </li>
              <li>
                <Link
                  to="/request-demo"
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300"
                  onClick={toggleMobileMenu}
                >
                  Request a Demo
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;