import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { FaRobot, FaPaperPlane, FaChartLine, FaBoxes, FaUsers, FaDollarSign, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const ChatbotFloater = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isInsightsOpen, setIsInsightsOpen] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const chatEndRef = useRef(null);
  const insightsRef = useRef(null);

  const demoConversation = useMemo(() => [
    { user: "What are the key drivers of our recent sales increase?", bot: "Our recent sales surge is driven by a 30% increase in online traffic and a successful promotional campaign in the electronics category, resulting in a 22% growth. I recommend sustaining these efforts to maintain momentum." },
    { user: "What’s the status of our inventory turnover?", bot: "The current inventory turnover is 6 times per year. While this is within industry norms, there is room for improvement. I suggest optimizing reorder levels for fast-moving items to improve turnover rates." },
    { user: "How satisfied are our customers?", bot: "Customer satisfaction has increased by 8% this quarter, with an overall satisfaction rate of 4.5/5. However, there is a slight dip in satisfaction among repeat customers. A targeted loyalty program might help address this." },
    { user: "What’s our revenue forecast for the next quarter?", bot: "The revenue forecast for the next quarter shows a 10% increase, driven by strong sales in the first half of the year. However, rising operational costs could reduce profit margins by 2-3%." },
  ], []);

  const typeMessage = useCallback(async (message) => {
    setUserInput('');
    for (let i = 0; i < message.length; i++) {
      await new Promise(resolve => setTimeout(resolve, 50)); // Simulate typing by adding each character
      setUserInput((prev) => prev + message[i]);
    }
  }, []);

  const animateConversation = useCallback(async () => {
    for (let message of demoConversation) {
      await typeMessage(message.user); // Simulate typing in the input box
      setChatHistory(prev => [...prev, { user: message.user }]);
      setUserInput('');
      setIsTyping(true);
      await new Promise(resolve => setTimeout(resolve, 1500)); // Simulate typing time for bot response
      setIsTyping(false);
      setChatHistory(prev => [...prev, { bot: message.bot }]);
    }
  }, [demoConversation, typeMessage]);

  useEffect(() => {
    if (isChatOpen) {
      setChatHistory([]);
      animateConversation();
    }
  }, [isChatOpen, animateConversation]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const sendMessage = () => {
    if (userInput.trim() !== '') {
      setChatHistory(prev => [...prev, { user: userInput }]);
      setUserInput('');
    }
  };

  const messageVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const insightsVariants = {
    open: { 
      opacity: 1,
      height: 'auto',
      marginBottom: 16,
      transition: { 
        duration: 0.3,
        ease: 'easeInOut'
      }
    },
    closed: { 
      opacity: 0,
      height: 0,
      marginBottom: 0,
      transition: { 
        duration: 0.3,
        ease: 'easeInOut'
      }
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <motion.button
        className="bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full p-4 shadow-lg focus:outline-none"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={toggleChat}
      >
        <FaRobot className="text-2xl" />
      </motion.button>
      <AnimatePresence>
        {isChatOpen && (
          <motion.div
            className="fixed bottom-20 right-4 w-80 md:w-96 bg-white rounded-lg shadow-2xl overflow-hidden"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <div className="bg-gradient-to-r from-blue-600 to-purple-600 p-4 flex items-center justify-between">
              <div className="flex items-center">
                <FaRobot className="text-white text-3xl mr-2" />
                <h2 className="text-white text-lg font-semibold">JaanchAI Assistant</h2>
              </div>
              <button onClick={toggleChat} className="text-white text-2xl focus:outline-none">&times;</button>
            </div>
            <div className="p-4">
              <button 
                className="w-full bg-gray-100 p-2 rounded-lg mb-4 flex items-center justify-between"
                onClick={() => setIsInsightsOpen(!isInsightsOpen)}
              >
                <span className="font-semibold">Business Insights Dashboard</span>
                {isInsightsOpen ? <FaChevronUp /> : <FaChevronDown />}
              </button>
              <motion.div
                ref={insightsRef}
                initial="closed"
                animate={isInsightsOpen ? "open" : "closed"}
                variants={insightsVariants}
                className="bg-gray-50 rounded-lg shadow-inner overflow-hidden"
              >
                <div className="p-4">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex items-center">
                      <FaChartLine className="text-blue-500 mr-2" />
                      <p className="text-sm">Sales: +15%</p>
                    </div>
                    <div className="flex items-center">
                      <FaBoxes className="text-green-500 mr-2" />
                      <p className="text-sm">Inventory: Optimal</p>
                    </div>
                    <div className="flex items-center">
                      <FaUsers className="text-yellow-500 mr-2" />
                      <p className="text-sm">Customer Satisfaction: 4.8/5</p>
                    </div>
                    <div className="flex items-center">
                      <FaDollarSign className="text-purple-500 mr-2" />
                      <p className="text-sm">Revenue Forecast: +12%</p>
                    </div>
                  </div>
                </div>
              </motion.div>
              <div className="h-60 overflow-y-auto mb-4 border border-gray-200 rounded-lg p-3">
                <AnimatePresence>
                  {chatHistory.map((message, index) => (
                    <motion.div
                      key={index}
                      className="mb-4"
                      variants={messageVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ duration: 0.5 }}
                    >
                      {message.user && (
                        <p className="text-gray-700 mb-1"><span className="font-semibold">You:</span> {message.user}</p>
                      )}
                      {message.bot && (
                        <div className="bg-blue-100 text-blue-800 p-3 rounded-lg">
                          <p className="whitespace-pre-wrap">{message.bot}</p>
                        </div>
                      )}
                    </motion.div>
                  ))}
                </AnimatePresence>
                {isTyping && (
                  <motion.div
                    className="text-gray-500 italic"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    JaanchAI is typing...
                  </motion.div>
                )}
                <div ref={chatEndRef} />
              </div>
              <div className="flex">
                <input
                  type="text"
                  className="flex-grow border border-gray-300 rounded-l-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Ask about sales, inventory, customers..."
                  value={userInput}
                  onChange={handleUserInput}
                  onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
                <button
                  className="bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-r-md px-4 py-2 focus:outline-none flex items-center justify-center"
                  onClick={sendMessage}
                >
                  <FaPaperPlane />
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ChatbotFloater;
