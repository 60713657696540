import React, { useState } from 'react';
import { FaBell, FaUserCircle, FaBars, FaChartLine, FaUsers, FaTags, FaBoxes, FaRobot, FaEnvelope, FaWhatsapp, FaTelegramPlane, FaHome, FaAngellist } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const DashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <div className={`bg-gray-800 text-white fixed h-full transition-all duration-300 ${isSidebarOpen ? 'w-64' : 'w-16'}`}>
        <div className="p-4 flex items-center justify-between">
          <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-2xl font-semibold`}>JaanchAI</span>
          <button onClick={toggleSidebar} className="text-white focus:outline-none">
            <FaBars />
          </button>
        </div>
        <nav className="mt-8 space-y-2">
            <Link to="/dashboard" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaHome className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Dashboard</span>
            </Link>
          <Link to="/sales-analytics" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaChartLine className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Sales Analytics</span>
          </Link>
          <Link to="/customers-insights" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaUsers className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Customer Insights</span>
          </Link>
          <Link to="/pricingview" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaTags className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Pricing Strategies</span>
          </Link>
          <Link to="/inventory" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaBoxes className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Inventory Management</span>
          </Link>
          <Link to="/marketing-campaigns" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaAngellist className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>
                Marketing Campaigns
            </span>
          </Link>
          <Link to="/automations" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaRobot className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Automations</span>
          </Link>
          <div className="ml-4">
            <Link to="/automations/whatsapp" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
              <FaWhatsapp className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>WhatsApp</span>
            </Link>
            <Link to="/automations/email" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
              <FaEnvelope className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Email</span>
            </Link>
            <Link to="/automations/telegram" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
              <FaTelegramPlane className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Telegram</span>
            </Link>
          </div>
        </nav>
      </div>

      {/* Main Content */}
      <div className={`flex-grow ${isSidebarOpen ? 'ml-64' : 'ml-16'} transition-all duration-300`}>
        {/* Navbar */}
        <header className="fixed top-0 left-0 right-0 bg-white p-4 shadow-md flex items-center justify-between z-10">
          <div className="flex items-center">
            <button onClick={toggleSidebar} className="text-gray-600 focus:outline-none mr-4">
              <FaBars />
            </button>
            <h1 className="text-2xl font-bold text-gray-800">JaanchAI</h1>
          </div>
          <div className="flex items-center space-x-4">
            <FaBell className="text-gray-600 text-2xl cursor-pointer hover:text-gray-800" />
            <div className="relative">
              <FaUserCircle className="text-gray-600 text-2xl cursor-pointer hover:text-gray-800" onClick={toggleProfileMenu} />
              {isProfileMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-10">
                  <Link to="/profile" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                    Profile
                  </Link>
                  <Link to="/settings" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                    Settings
                  </Link>
                  <Link to="/logout" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                    Logout
                  </Link>
                </div>
              )}
            </div>
          </div>
        </header>

        {/* Content */}
        <main className="p-8 flex-grow mt-16">
          {children}
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
