import React from 'react';
import { FaPlay, FaPlug, FaChartLine } from 'react-icons/fa';

const GettingStarted = () => {
  return (
    <section className="bg-gray-900 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-white">Getting started is easy!</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <FaPlay className="text-4xl text-blue-500 mb-4 mx-auto" />
            <h3 className="text-2xl font-semibold mb-2">Request a Demo</h3>
            <p className="text-gray-600">Get in touch with us to see JaanchAI in action and understand how it can benefit your business.</p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <FaPlug className="text-4xl text-green-500 mb-4 mx-auto" />
            <h3 className="text-2xl font-semibold mb-2">Connect Your Data</h3>
            <p className="text-gray-600">Seamlessly connect your data to JaanchAI for comprehensive analysis and actionable insights.</p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <FaChartLine className="text-4xl text-purple-500 mb-4 mx-auto" />
            <h3 className="text-2xl font-semibold mb-2">Review Insights</h3>
            <p className="text-gray-600">Review the insights provided by JaanchAI and take action to optimize your business strategies.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GettingStarted;
