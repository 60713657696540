import React, { useState } from 'react';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { FaTrash } from 'react-icons/fa';
import { useDrop } from 'react-dnd';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';

Chart.register(CategoryScale);

const KPIContainer = () => {
  const [addedKPIs, setAddedKPIs] = useState([]);

  const [{ isOver }, drop] = useDrop({
    accept: 'KPI',
    drop: (item) => addKPI(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const addKPI = (kpi) => {
    if (!addedKPIs.some((existingKpi) => existingKpi.id === kpi.id)) {
      setAddedKPIs((prevKpis) => [...prevKpis, kpi]);
    }
  };

  const removeKPI = (id) => {
    setAddedKPIs((prevKpis) => prevKpis.filter((kpi) => kpi.id !== id));
  };

  const salesData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Sales',
        data: [12000, 15000, 17000, 20000, 25000, 30000],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const inventoryData = {
    labels: ['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
    datasets: [
      {
        label: 'Stock Level',
        data: [50, 75, 150, 125, 200],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(192, 75, 75, 0.6)',
          'rgba(75, 75, 192, 0.6)',
          'rgba(192, 192, 75, 0.6)',
          'rgba(75, 192, 75, 0.6)',
        ],
      },
    ],
  };

  const customerSatisfactionData = {
    labels: ['Very Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very Dissatisfied'],
    datasets: [
      {
        label: 'Customer Satisfaction',
        data: [60, 25, 10, 3, 2],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(75, 75, 192, 0.6)',
          'rgba(192, 192, 75, 0.6)',
          'rgba(192, 75, 75, 0.6)',
          'rgba(75, 192, 75, 0.6)',
        ],
      },
    ],
  };

  const revenueData = {
    labels: ['Q1', 'Q2', 'Q3', 'Q4'],
    datasets: [
      {
        label: 'Revenue',
        data: [50000, 60000, 70000, 80000],
        backgroundColor: [
          'rgba(192, 75, 192, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(75, 75, 192, 0.6)',
          'rgba(192, 192, 75, 0.6)',
        ],
      },
    ],
  };

  return (
    <div
      ref={drop}
      className={`p-4 border-2 border-dashed ${isOver ? 'border-blue-500' : 'border-gray-400'} rounded-lg grid grid-cols-1 md:grid-cols-2 gap-8 w-full h-full`}
    >
      {addedKPIs.length === 0 ? (
        <p className="text-center w-full">Drop KPIs here to analyze</p>
      ) : (
        addedKPIs.map((kpi) => (
          <div key={kpi.id} className="bg-white p-6 rounded shadow-md relative" style={{ height: '350px' }}>
            <button
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
              onClick={() => removeKPI(kpi.id)}
            >
              <FaTrash />
            </button>
            <h3 className="text-xl font-semibold mb-4">{kpi.name}</h3>
            <div style={{ height: '250px' }}>
              {kpi.name === 'Sales' && <Line data={salesData} options={{ maintainAspectRatio: false }} />}
              {kpi.name === 'Inventory' && <Bar data={inventoryData} options={{ maintainAspectRatio: false }} />}
              {kpi.name === 'Customer Satisfaction' && <Doughnut data={customerSatisfactionData} options={{ maintainAspectRatio: false }} />}
              {kpi.name === 'Revenue' && <Bar data={revenueData} options={{ maintainAspectRatio: false }} />}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default KPIContainer;
