import React from 'react';
import { FaChartLine, FaChartPie, FaRobot } from 'react-icons/fa';

const JaanchCopilot = () => {
  return (
    <section className="bg-gray-900 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 text-center text-white">JaanchCopilot</h2>
        <p className="text-xl mb-12 text-center text-gray-300">
          Empower your decision-making with JaanchCopilot. Our advanced AI engine harnesses the power of real-time data analysis, delivering actionable insights that drive business growth and profitability.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 md:p-8">
            <div className="flex items-center mb-4">
              <FaChartLine className="text-4xl text-blue-500 mr-4" />
              <h3 className="text-2xl font-bold text-white">Sales Insight</h3>
            </div>
            <ul className="list-disc pl-6 text-gray-300">
              <li className="mb-2">Analyze sales trends with precision, identifying growth opportunities and areas of concern.</li>
              <li className="mb-2">Get a detailed view of product performance across multiple dimensions, including time, region, and category.</li>
              <li>Utilize AI-driven insights to enhance your sales strategy and boost revenue growth.</li>
            </ul>
          </div>
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 md:p-8">
            <div className="flex items-center mb-4">
              <FaChartPie className="text-4xl text-green-500 mr-4" />
              <h3 className="text-2xl font-bold text-white">Margin Management</h3>
            </div>
            <ul className="list-disc pl-6 text-gray-300">
              <li className="mb-2">Identify the key drivers of profitability and focus on high-margin products.</li>
              <li className="mb-2">Gain deep insights into cost structures and refine your pricing strategies for maximum impact.</li>
              <li>Optimize your margin management with data-backed decisions, enhancing overall profitability.</li>
            </ul>
          </div>
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 md:p-8">
            <div className="flex items-center mb-4">
              <FaRobot className="text-4xl text-purple-500 mr-4" />
              <h3 className="text-2xl font-bold text-white">AI CoPilot Querying</h3>
            </div>
            <ul className="list-disc pl-6 text-gray-300">
              <li className="mb-2">Harness the power of AI for 10X faster data analysis using natural language processing.</li>
              <li className="mb-2">Effortlessly query complex datasets with intuitive, conversational language.</li>
              <li className="mb-2">Receive precise, actionable insights that answer your most pressing business questions.</li>
              <li>Accelerate your decision-making process with real-time, AI-driven responses.</li>
            </ul>
          </div>
        </div>
        <div className="mt-12 text-center">
          <button className="bg-blue-500 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-blue-600 transition-colors duration-300">
            Get Started with JaanchCopilot
          </button>
        </div>
      </div>
    </section>
  );
};

export default JaanchCopilot;
