import React, { useState, useEffect } from 'react';
import { FaRobot, FaBoxes, FaBrain, FaPercentage, FaChartLine } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const solutions = [
  {
    title: "Sales AI",
    description: "Optimize your sales strategy with AI-driven insights. Track performance, predict trends, and take action to maximize revenue across your entire product line.",
    backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")',
    ctaText: "Explore Sales AI",
    ctaLink: "/sales-ai",
    icon: <FaChartLine />,
  },
  {
    title: "Inventory AI",
    description: "Streamline your inventory management with real-time data and AI-powered forecasts. Prevent overstocking, reduce carrying costs, and ensure optimal stock levels.",
    backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")',
    ctaText: "Discover Inventory AI",
    ctaLink: "/inventory-ai",
    icon: <FaBoxes />,
  },
  {
    title: "Pricing AI",
    description: "Implement dynamic pricing strategies that maximize profit margins and stay competitive. Use AI to predict the impact of price changes and optimize pricing decisions.",
    backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")',
    ctaText: "Learn About Pricing AI",
    ctaLink: "/pricing-ai",
    icon: <FaPercentage />,
  },
  {
    title: "Automation AI",
    description: "Automate business processes and communications with real-time AI-driven alerts and actions. Connect with WhatsApp, Telegram, and Email to stay ahead of the curve.",
    backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")',
    ctaText: "See Automation in Action",
    ctaLink: "/automation-ai",
    icon: <FaRobot />,
  },
  {
    title: "Promotion AI",
    description: "Maximize the ROI of your promotional campaigns. Use AI to analyze past promotions, predict outcomes, and optimize future strategies for better results.",
    backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")',
    ctaText: "Explore Promotion Optimization",
    ctaLink: "/promotion-ai",
    icon: <FaPercentage />,
  },
  {
    title: "Forecast AI",
    description: "Enhance planning accuracy with AI-driven sales forecasts. Anticipate demand, optimize stock levels, and ensure you meet your customers' needs every time.",
    backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")',
    ctaText: "Improve Forecasting",
    ctaLink: "/forecast-ai",
    icon: <FaBrain />,
  },
  {
    title: "Generative Insights Engine",
    description: "Uncover actionable insights from vast data sources. JaanchAI's Generative Insights Engine processes and analyzes data to drive informed business decisions.",
    backgroundImage: 'url("https://www.transparenttextures.com/patterns/cubes.png")',
    ctaText: "Get Insights",
    ctaLink: "/insights-engine",
    icon: <FaBrain />,
  },
];

const HeroBanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % solutions.length);
    }, 5000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <section 
      id="hero-banner" 
      className="text-white min-h-screen flex flex-col justify-center relative overflow-hidden"
      style={{ backgroundImage: solutions[currentIndex].backgroundImage }}
    >
      <div className="absolute inset-0 bg-pattern opacity-10"></div>
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-7/12 text-center md:text-left mb-8 md:mb-0">
            <h1 className="text-4xl sm:text-5xl font-bold mb-4">Discover the Power of JaanchAI</h1>
            <div className="flex flex-col sm:flex-row items-center justify-center md:justify-start">
              <div className="text-6xl text-blue-500 mb-4 sm:mb-0 sm:mr-4">{solutions[currentIndex].icon}</div>
              <h3 className="text-2xl sm:text-3xl font-bold">{solutions[currentIndex].title}</h3>
            </div>
          </div>
          <div className="md:w-5/12">
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center md:text-left">
              <p className="text-lg sm:text-xl">{solutions[currentIndex].description}</p>
              <Link
                to={solutions[currentIndex].ctaLink}
                className="bg-blue-700 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-800 transition-colors duration-300 inline-block mt-4"
              >
                {solutions[currentIndex].ctaText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
