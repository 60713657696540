import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { FaTags, FaArrowCircleRight, FaMoneyBillWave, FaArrowUp } from 'react-icons/fa';
import DashboardLayout from './DashboardLayout';
import Modal from './Modal';

const PricingStrategies = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const pricingData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Revenue Impact',
        data: [32000, 35000, 40000, 43000, 47000, 50000],
        fill: false,
        borderColor: '#FF6384',
        tension: 0.1,
      },
    ],
  };

  const marginData = {
    labels: ['Smartphone Pro Max', 'Smartwatch X2', 'Laptop Pro 15', 'Wireless Earbuds', '4K TV Ultra'],
    datasets: [
      {
        label: 'Profit Margin (%)',
        data: [25, 30, 20, 35, 40],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ],
      },
    ],
  };

  const averageSellingPrice = 499;
  const targetMargin = 35;

  const renderPriceSimulation = () => (
    <div>
      <p className="mb-4">Adjust the price of products to see the potential impact on revenue and margin:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Select Product</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>Smartphone Pro Max</option>
            <option>Smartwatch X2</option>
            <option>Laptop Pro 15</option>
            <option>Wireless Earbuds</option>
            <option>4K TV Ultra</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">New Price ($)</label>
          <input type="number" className="w-full border-gray-300 rounded-md" placeholder="Enter new price" />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Simulate Price Change
        </button>
      </form>
    </div>
  );

  const renderMarginImprovement = () => (
    <div>
      <p className="mb-4">Identify ways to improve profit margins for your products:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Negotiate better terms with suppliers to reduce costs.</li>
        <li>Optimize the pricing model to increase the selling price while maintaining customer satisfaction.</li>
        <li>Enhance product features to justify a higher price point.</li>
      </ul>
      <button type="button" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
        Implement Strategy
      </button>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
          <FaTags className="text-blue-500 mr-2" /> Pricing Strategies
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-yellow-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaMoneyBillWave className="text-yellow-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-yellow-800">Average Selling Price</p>
              <p className="text-2xl font-bold text-yellow-900">${averageSellingPrice}</p>
              <button
                onClick={() => handleModalOpen('Price Simulation', renderPriceSimulation())}
                className="ml-2 mt-2 inline-block bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
              >
                Adjust Prices <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>

          <div className="bg-green-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaArrowUp className="text-green-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-green-800">Target Profit Margin</p>
              <p className="text-2xl font-bold text-green-900">{targetMargin}%</p>
              <button
                onClick={() => handleModalOpen('Improve Margins', renderMarginImprovement())}
                className="ml-2 mt-2 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
              >
                Improve Margins <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>
        </div>

        <div className="bg-gray-100 p-6 rounded-lg shadow-inner mb-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Revenue Impact Over Time</h3>
          <Line data={pricingData} />
        </div>

        <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Profit Margin by Product</h3>
          <Bar data={marginData} />
        </div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default PricingStrategies;
