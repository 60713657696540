import React, { useState } from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import { FaSmile, FaSyncAlt, FaUsers, FaArrowCircleRight } from 'react-icons/fa';
import DashboardLayout from './DashboardLayout';
import Modal from './Modal'; // Import the Modal component

const CustomerInsights = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const satisfactionData = {
    labels: ['Very Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very Dissatisfied'],
    datasets: [
      {
        label: 'Customer Satisfaction',
        data: [45, 30, 15, 7, 3],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(255, 99, 132, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ],
      },
    ],
  };

  const churnData = {
    labels: ['18-25', '26-35', '36-45', '46-55', '55+'],
    datasets: [
      {
        label: 'Churn Rate by Age Group',
        data: [12, 8, 15, 10, 5],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
    ],
  };

  const lifetimeValue = 480;
  const churnRate = 10;

  const renderRetentionCampaign = () => (
    <div>
      <p className="mb-4">Target specific customer segments with personalized offers:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Select Age Group</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>36-45</option>
            <option>26-35</option>
            <option>18-25</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Offer Type</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>Discount</option>
            <option>Free Trial</option>
            <option>Loyalty Points</option>
          </select>
        </div>
        <button type="submit" className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
          Launch Campaign
        </button>
      </form>
    </div>
  );

  const renderFeedbackLoop = () => (
    <div>
      <p className="mb-4">Send a survey to gather feedback from neutral and dissatisfied customers:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Survey Type</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>Customer Satisfaction Survey</option>
            <option>Net Promoter Score (NPS)</option>
            <option>Product Feedback</option>
          </select>
        </div>
        <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          Send Survey
        </button>
      </form>
    </div>
  );

  const renderLoyaltyProgram = () => (
    <div>
      <p className="mb-4">Set up a loyalty program for high-value customers:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Reward Type</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>Points</option>
            <option>Discounts</option>
            <option>Exclusive Offers</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Target Spend</label>
          <input type="number" className="w-full border-gray-300 rounded-md" placeholder="Enter amount" />
        </div>
        <button type="submit" className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">
          Set Up Program
        </button>
      </form>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
          <FaUsers className="text-blue-500 mr-2" /> Customer Insights
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-green-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaSmile className="text-green-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-green-800">Customer Satisfaction</p>
              <p className="text-2xl font-bold text-green-900">85%</p>
            </div>
          </div>

          <div className="bg-red-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaSyncAlt className="text-red-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-red-800">Churn Rate</p>
              <p className="text-2xl font-bold text-red-900">{churnRate}%</p>
            </div>
          </div>

          <div className="bg-yellow-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaUsers className="text-yellow-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-yellow-800">Customer Lifetime Value</p>
              <p className="text-2xl font-bold text-yellow-900">${lifetimeValue}</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg shadow-inner mb-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Satisfaction Distribution</h3>
          <div className="h-48">
            <Doughnut data={satisfactionData} options={{ maintainAspectRatio: false }} />
          </div>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Churn Rate by Age Group</h3>
          <div className="h-48">
            <Bar data={churnData} options={{ maintainAspectRatio: false }} />
          </div>
        </div>

        <div className="mt-6 p-4 bg-blue-50 rounded-lg shadow-inner">
          <h3 className="text-lg font-semibold text-blue-800 mb-4">Actionable Insights</h3>
          <ul className="list-disc list-inside">
            <li className="text-gray-700 mb-2">
              <strong>Focus on Retention:</strong> The 10% churn rate is a concern.
              <button
                onClick={() => handleModalOpen('Launch Retention Campaign', renderRetentionCampaign())}
                className="ml-2 inline-block bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                Launch Retention Campaign <FaArrowCircleRight className="inline ml-1" />
              </button>
            </li>
            <li className="text-gray-700 mb-2">
              <strong>Enhance Satisfaction:</strong> Although 85% of customers are satisfied, improving the experience for the remaining 15% could boost loyalty.
              <button
                onClick={() => handleModalOpen('Initiate Feedback Loop', renderFeedbackLoop())}
                className="ml-2 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
              >
                Initiate Feedback Loop <FaArrowCircleRight className="inline ml-1" />
              </button>
            </li>
            <li className="text-gray-700 mb-2">
              <strong>Increase Lifetime Value:</strong> Targeted loyalty programs could increase the lifetime value of customers, especially in the 26-35 age group.
              <button
                onClick={() => handleModalOpen('Explore Loyalty Programs', renderLoyaltyProgram())}
                className="ml-2 inline-block bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
              >
                Explore Loyalty Programs <FaArrowCircleRight className="inline ml-1" />
              </button>
            </li>
          </ul>
        </div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default CustomerInsights;
