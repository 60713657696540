import React from 'react';

const FAQ = () => {
  const faqs = [
    {
      question: 'What is JaanchAI?',
      answer: `JaanchAI is a cutting-edge AI-driven platform designed to simplify business analytics for retailers. It helps businesses optimize key areas such as pricing strategies, promotional effectiveness, competitor analysis, inventory management, and sales forecasting. By providing actionable insights in real time, JaanchAI enables businesses to make data-driven decisions that lead to improved efficiency, higher profits, and a competitive edge.`,
    },
    {
      question: 'How does JaanchAI improve sales?',
      answer: `JaanchAI boosts sales by leveraging advanced machine learning models to analyze customer behavior, sales trends, and product performance. It identifies high-potential opportunities for growth through customer segmentation and personalized product recommendations. Additionally, JaanchAI helps retailers implement targeted promotions, increase customer retention, and optimize pricing strategies, all of which result in a significant increase in revenue.`,
    },
    {
      question: 'Can I integrate JaanchAI with my existing tools?',
      answer: `Absolutely! JaanchAI seamlessly integrates with a wide range of popular business tools and platforms, including e-commerce systems, CRM software, and marketing automation platforms. It also offers native integrations with communication tools like WhatsApp, Telegram, and email, allowing you to automate reports, notifications, and promotional campaigns, making your workflow more efficient.`,
    },
    {
      question: 'Is JaanchAI secure?',
      answer: `Yes, security is a top priority at JaanchAI. The platform uses industry-leading encryption standards to ensure that all of your data is secure and accessible only by authorized users. Our robust security protocols include data encryption at rest and in transit, along with regular security audits to safeguard your sensitive business information from unauthorized access.`,
    },
    {
      question: 'How do I get started with JaanchAI?',
      answer: `Getting started with JaanchAI is simple. You can request a personalized demo by visiting our "Request a Demo" page, where our team will walk you through the platform’s features and how it can be tailored to meet your specific business needs. You'll discover how JaanchAI can help you optimize operations, drive sales, and stay ahead of the competition.`,
    },
    {
      question: 'What kind of businesses can benefit from JaanchAI?',
      answer: `JaanchAI is designed to support a wide range of retail businesses, from small e-commerce stores to large retail chains. Whether you’re focused on optimizing pricing, managing inventory, or improving customer retention, JaanchAI provides the data-driven insights needed to thrive in today’s competitive market.`,
    },
  ];

  return (
    <div className="container mx-auto py-16 mt-16 px-4">
      <h2 className="text-4xl font-bold text-center text-gray-900 mb-12">
        Frequently Asked Questions
      </h2>
      <div className="max-w-4xl mx-auto space-y-8">
        {faqs.map((faq, index) => (
          <div key={index} className="p-6 bg-gray-100 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-blue-600 mb-4">{faq.question}</h3>
            <p className="text-gray-700">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;

