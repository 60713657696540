import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCogs, FaArrowCircleRight } from 'react-icons/fa';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const EnhanceEfficiency = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderAutomationStrategyCTA = () => (
    <div>
      <p className="mb-4">To improve efficiency and reduce manual workload, consider these automation strategies:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Automate order processing to streamline fulfillment.</li>
        <li>Use AI to update inventory levels in real-time.</li>
        <li>Generate shipping labels with minimal manual intervention.</li>
      </ul>
      <Link to="/request-demo" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 inline-block mt-4">
        Request Demo <FaArrowCircleRight className="inline ml-1" />
      </Link>
    </div>
  );

  const renderEfficiencyImprovementCTA = () => (
    <div>
      <p className="mb-4">To further enhance operational efficiency, you could:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Implement AI-driven recommendations for inventory management.</li>
        <li>Streamline product listings and update them automatically across multiple platforms.</li>
        <li>Leverage AI to optimize order fulfillment and reduce processing time.</li>
      </ul>
      <Link to="/request-demo" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 inline-block mt-4">
        Request Demo <FaArrowCircleRight className="inline ml-1" />
      </Link>
    </div>
  );

  return (
    <section id="enhance-efficiency" className="py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl font-bold mb-8 text-center md:text-left">Work Smarter, Not Harder</h2>
          <div className="flex flex-col md:flex-row items-center mb-8">
            <div className="md:w-1/3 mb-4 md:mb-0">
              <FaCogs className="text-4xl text-red-500 mx-auto md:mx-0" />
            </div>
            <div className="md:w-2/3">
              <p className="text-lg text-center md:text-left">
                JaanchAI automates repetitive tasks and provides intelligent recommendations, allowing your team to focus on high-value activities. It streamlines product listings, order management, and fulfillment processes, making your business operate more efficiently and effectively.
              </p>
            </div>
          </div>
          <div className="bg-red-100 rounded-lg p-6 shadow-lg mb-6">
            <h3 className="text-xl font-bold mb-4">Handmade Jewelry Store Example</h3>
            <p className="text-lg mb-4">
              Problem: A handmade jewelry store was struggling with manual order processing and inventory management. The owner spent hours each day manually entering orders into spreadsheets, updating inventory levels, and generating shipping labels. This time-consuming process limited their ability to focus on creating new designs and growing the business.
            </p>
            <p className="text-lg">
              Solution: By implementing JaanchAI, the jewelry store automated their order processing and inventory management. JaanchAI automatically synced orders from their e-commerce platform, updated inventory levels in real-time, and generated shipping labels with just a few clicks. This automation reduced manual order processing time by 80%, freeing up the owner to focus on designing new products and marketing their business. As a result, the store saw a 25% increase in sales and improved customer satisfaction due to faster order fulfillment.
            </p>
            <button
              onClick={() => handleModalOpen('Implement Automation', renderAutomationStrategyCTA())}
              className="ml-2 mt-4 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
            >
              Implement Automation <FaArrowCircleRight className="inline ml-1" />
            </button>
            <button
              onClick={() => handleModalOpen('Enhance Efficiency', renderEfficiencyImprovementCTA())}
              className="ml-2 mt-4 inline-block bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            >
              Enhance Efficiency <FaArrowCircleRight className="inline ml-1" />
            </button>
          </div>
        </motion.div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </section>
  );
};

export default EnhanceEfficiency;
