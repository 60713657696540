import React, { useState } from 'react';
import { FaChartLine, FaArrowCircleRight } from 'react-icons/fa';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const BoostSales = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderRetentionStrategy = () => (
    <div>
      <p className="mb-4">To reduce the churn rate and boost retention, consider the following strategies:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Implement targeted retention campaigns for high-risk customers.</li>
        <li>Offer loyalty programs or incentives for long-term customers.</li>
        <li>Enhance customer support with AI-driven solutions.</li>
      </ul>
      <Link to="/request-demo" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
        Request Demo <FaArrowCircleRight className="inline ml-1" />
      </Link>
    </div>
  );

  const renderSatisfactionImprovement = () => (
    <div>
      <p className="mb-4">To enhance customer satisfaction, you could:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Collect customer feedback through surveys and implement changes.</li>
        <li>Personalize customer interactions using AI-driven insights.</li>
        <li>Provide quicker resolutions to customer issues with a dedicated support team.</li>
      </ul>
      <Link to="/request-demo" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Request Demo <FaArrowCircleRight className="inline ml-1" />
      </Link>
    </div>
  );

  const renderLoyaltyProgram = () => (
    <div>
      <p className="mb-4">Increase customer lifetime value with a tailored loyalty program:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Select Age Group</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>18-25</option>
            <option>26-35</option>
            <option>36-45</option>
          </select>
        </div>
        <Link to="/request-demo" className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">
          Request Demo <FaArrowCircleRight className="inline ml-1" />
        </Link>
      </form>
    </div>
  );

  return (
    <section id="boost-sales" className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center md:text-left">
          Boost Your Sales
        </h2>
        <div className="flex flex-col md:flex-row items-center mb-8">
          <div className="md:w-1/3 mb-4 md:mb-0">
            <FaChartLine className="text-6xl text-blue-500 mx-auto md:mx-0" />
          </div>
          <div className="md:w-2/3">
            <p className="text-lg text-center md:text-left">
              JaanchAI leverages advanced analytics and customer segmentation to identify opportunities for increasing sales and customer retention. By providing personalized product recommendations and targeted promotions, JaanchAI helps you maximize customer lifetime value and drive revenue growth.
            </p>
          </div>
        </div>
        <div className="bg-blue-100 rounded-lg p-6 shadow-lg mb-6">
          <h3 className="text-xl font-bold mb-4">Cosmetics E-commerce Example</h3>
          <p className="text-lg mb-4">
            Problem: A cosmetics e-commerce store was experiencing high customer churn rates and struggling to increase sales. They had a broad customer base but lacked insights into customer preferences and behavior, making it difficult to engage and retain customers effectively.
          </p>
          <p className="text-lg">
            Solution: JaanchAI analyzed the store's customer data and identified distinct customer segments based on purchase history, browsing behavior, and demographic information. By understanding the unique preferences and needs of each segment, the store was able to create targeted email campaigns and personalized product recommendations. This led to a 30% increase in customer retention and a 20% boost in sales revenue within three months.
          </p>
          <button
            onClick={() => handleModalOpen('Focus on Retention', renderRetentionStrategy())}
            className="ml-2 mt-4 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
          >
            Focus on Retention <FaArrowCircleRight className="inline ml-1" />
          </button>
          <button
            onClick={() => handleModalOpen('Enhance Satisfaction', renderSatisfactionImprovement())}
            className="ml-2 mt-4 inline-block bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
          >
            Enhance Satisfaction <FaArrowCircleRight className="inline ml-1" />
          </button>
          <button
            onClick={() => handleModalOpen('Increase Lifetime Value', renderLoyaltyProgram())}
            className="ml-2 mt-4 inline-block bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
          >
            Increase Lifetime Value <FaArrowCircleRight className="inline ml-1" />
          </button>
        </div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </section>
  );
};

export default BoostSales;
