import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 p-6 text-gray-300">
      <div className="container mx-auto px-4 text-center">
        <div className="mb-4">
          <h2 className="text-xl font-semibold">Stay Connected with Us</h2>
          <div className="flex justify-center space-x-4 mt-2">
            <a href="https://facebook.com" className="text-gray-300 hover:text-white transition-colors duration-300">
              <FaFacebook className="text-2xl" />
            </a>
            <a href="https://twitter.com" className="text-gray-300 hover:text-white transition-colors duration-300">
              <FaTwitter className="text-2xl" />
            </a>
            <a href="https://linkedin.com" className="text-gray-300 hover:text-white transition-colors duration-300">
              <FaLinkedin className="text-2xl" />
            </a>
            <a href="https://instagram.com" className="text-gray-300 hover:text-white transition-colors duration-300">
              <FaInstagram className="text-2xl" />
            </a>
          </div>
        </div>
        <nav className="mb-4">
          <ul className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4">
            <li><a href="#privacy" className="hover:text-white transition-colors duration-300">Privacy</a></li>
            <li><a href="#terms" className="hover:text-white transition-colors duration-300">Terms</a></li>
            <li><a href="#contact" className="hover:text-white transition-colors duration-300">Contact Us</a></li>
          </ul>
        </nav>
        <div className="mb-4">© 2024 Jaanch Inc.</div>
        <div className="mb-4">
          <p className="text-sm">
            Email: <a href="mailto:info@jaanch.com" className="hover:text-white transition-colors duration-300">gopal@jaanch.ai</a>
          </p>
        </div>
        <div className="text-sm text-gray-400">
          Made in India with ❤️.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
