import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainContent from './components/MainContent';
import GettingStarted from './components/GettingStarted';
import NoCodeImplementations from './components/NoCodeImplementations';
import Solutions from './components/Solutions';
import withHeader from './components/withHeader';
import Dashboard from './components/Dashboard';
import ChatbotFloater from './components/ChatbotFloater';
import CalendlyScheduler from './components/CalendlyScheduler';
import DemoRequestForm from './components/DemoRequestForm';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SalesAnalytics from './components/SalesAnalytics';
import CustomerInsights from './components/CustomerInsights';
import PricingStrategies from './components/PricingStrategies';
import InventoryManagement from './components/InventoryManagement';
import MarketingCampaigns from './components/MarketingCampaigns';
import Automation from './components/Automation';
import WhatsAppStyleChat from './components/WhatsAppStyleChat';
import JaanchAIOverview from './components/JaanchGPTOverview';
import FAQ from './components/FAQ';

import './index.css';

const LandingPage = () => {
  return (
    <>
      <MainContent />
      <JaanchAIOverview />
      <GettingStarted />
      <NoCodeImplementations />
    </>
  );
};

const LandingPageWithHeader = withHeader(LandingPage);
const SolutionsWithHeader = withHeader(Solutions);
const DemoRequestFormWithHeader = withHeader(DemoRequestForm);
const CalendlySchedulerWithHeader = withHeader(CalendlyScheduler);
const FAQWithHeader = withHeader(FAQ);


function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPageWithHeader />} />
            <Route path="/solutions" element={<SolutionsWithHeader />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/talk-to-expert" element={<CalendlySchedulerWithHeader />} />
            <Route path="/request-demo" element={<DemoRequestFormWithHeader />} />
            <Route path="/sales-analytics" element={<SalesAnalytics />} />
            <Route path="/customers-insights" element={<CustomerInsights />} />
            <Route path="/pricingview" element={<PricingStrategies />} />
            <Route path="/inventory" element={<InventoryManagement />} />
            <Route path="/marketing-campaigns" element={<MarketingCampaigns />} />
            <Route path="/automations" element={<Automation />} />
            <Route path="/whatsapp-chat" element={<WhatsAppStyleChat />} />
            <Route path="/faq" element={<FAQWithHeader />} />
          </Routes>
          <ChatbotFloater />
        </div>
      </Router>
    </DndProvider>
  );
}

export default App;
