import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaBoxes, FaArrowCircleRight } from 'react-icons/fa';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const StreamlineInventory = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderStockoutPreventionCTA = () => (
    <div>
      <p className="mb-4">To prevent stockouts and optimize inventory levels, consider these actions:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Implement real-time inventory tracking for better visibility.</li>
        <li>Use AI to predict demand fluctuations and adjust stock levels accordingly.</li>
        <li>Automate reordering processes to ensure timely restocking.</li>
      </ul>
      <Link to="/request-demo" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 inline-block mt-4">
        Request Demo <FaArrowCircleRight className="inline ml-1" />
      </Link>
    </div>
  );

  const renderExcessInventoryReductionCTA = () => (
    <div>
      <p className="mb-4">To reduce excess inventory and improve cash flow, you could:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Analyze slow-moving products and plan clearance strategies.</li>
        <li>Use dynamic pricing to accelerate the sale of overstocked items.</li>
        <li>Implement just-in-time inventory practices to minimize excess stock.</li>
      </ul>
      <Link to="/request-demo" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 inline-block mt-4">
        Request Demo <FaArrowCircleRight className="inline ml-1" />
      </Link>
    </div>
  );

  return (
    <section id="streamline-inventory" className="py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl font-bold mb-8 text-center md:text-left">Better Inventory Management</h2>
          <div className="flex flex-col md:flex-row items-center mb-8">
            <div className="md:w-1/3 mb-4 md:mb-0">
              <FaBoxes className="text-4xl text-purple-500 mx-auto md:mx-0" />
            </div>
            <div className="md:w-2/3">
              <p className="text-lg text-center md:text-left">
                JaanchAI provides real-time inventory visibility and intelligent demand forecasting to help you optimize stock levels and avoid stockouts or overstocking. By analyzing sales trends, supplier lead times, and consumer behavior, JaanchAI enables you to make data-driven inventory decisions that minimize carrying costs and maximize product availability.
              </p>
            </div>
          </div>
          <div className="bg-purple-100 rounded-lg p-6 shadow-lg mb-6">
            <h3 className="text-xl font-bold mb-4">Fashion Boutique Example</h3>
            <p className="text-lg mb-4">
              Problem: A fashion boutique struggled with frequent stockouts of popular items and excess inventory of slow-moving products. They relied on manual inventory tracking and gut-feel decisions, leading to lost sales and tied-up capital in underperforming inventory.
            </p>
            <p className="text-lg">
              Solution: JaanchAI integrated with the boutique's point-of-sale and inventory management systems to provide real-time visibility into stock levels and sales velocity. By analyzing historical sales data and seasonal trends, JaanchAI generated accurate demand forecasts and recommended optimal stock levels for each product. This led to a 40% reduction in stockouts, a 20% decrease in excess inventory, and a 15% increase in inventory turnover, ultimately improving cash flow and customer satisfaction.
            </p>
            <button
              onClick={() => handleModalOpen('Prevent Stockouts', renderStockoutPreventionCTA())}
              className="ml-2 mt-4 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
            >
              Prevent Stockouts <FaArrowCircleRight className="inline ml-1" />
            </button>
            <button
              onClick={() => handleModalOpen('Reduce Excess Inventory', renderExcessInventoryReductionCTA())}
              className="ml-2 mt-4 inline-block bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            >
              Reduce Excess Inventory <FaArrowCircleRight className="inline ml-1" />
            </button>
          </div>
        </motion.div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </section>
  );
};

export default StreamlineInventory;
