import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { FaBoxes, FaArrowCircleRight, FaTruckLoading, FaRedoAlt } from 'react-icons/fa';
import DashboardLayout from './DashboardLayout';
import Modal from './Modal';

const InventoryManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const stockLevels = {
    labels: ['Smartphone Pro Max', 'Smartwatch X2', 'Laptop Pro 15', 'Wireless Earbuds', '4K TV Ultra'],
    datasets: [
      {
        label: 'Stock Level (Units)',
        data: [150, 200, 80, 300, 50],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
        ],
      },
    ],
  };

  const turnoverData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Inventory Turnover Ratio',
        data: [5.2, 5.5, 6.0, 5.8, 6.2, 6.5],
        fill: false,
        borderColor: '#4CAF50',
        tension: 0.1,
      },
    ],
  };

  const stockoutRate = 8;

  const renderReorderStock = () => (
    <div>
      <p className="mb-4">Reorder stock for low inventory items:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Select Product</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>Smartphone Pro Max</option>
            <option>Smartwatch X2</option>
            <option>Laptop Pro 15</option>
            <option>Wireless Earbuds</option>
            <option>4K TV Ultra</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Order Quantity</label>
          <input type="number" className="w-full border-gray-300 rounded-md" placeholder="Enter quantity" />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Reorder Stock
        </button>
      </form>
    </div>
  );

  const renderAdjustInventory = () => (
    <div>
      <p className="mb-4">Adjust inventory strategies to improve turnover and reduce stockouts:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Implement a Just-In-Time (JIT) inventory system to reduce holding costs.</li>
        <li>Increase order frequency for fast-moving products.</li>
        <li>Conduct regular inventory audits to identify discrepancies.</li>
      </ul>
      <button type="button" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
        Implement Strategy
      </button>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
          <FaBoxes className="text-green-500 mr-2" /> Inventory Management
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-yellow-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaTruckLoading className="text-yellow-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-yellow-800">Stockout Rate</p>
              <p className="text-2xl font-bold text-yellow-900">{stockoutRate}%</p>
              <button
                onClick={() => handleModalOpen('Reorder Stock', renderReorderStock())}
                className="ml-2 mt-2 inline-block bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
              >
                Reorder Stock <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>

          <div className="bg-green-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaRedoAlt className="text-green-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-green-800">Inventory Turnover</p>
              <p className="text-2xl font-bold text-green-900">{turnoverData.datasets[0].data.slice(-1)[0]}</p>
              <button
                onClick={() => handleModalOpen('Adjust Inventory Strategies', renderAdjustInventory())}
                className="ml-2 mt-2 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
              >
                Adjust Strategy <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>
        </div>

        <div className="bg-gray-100 p-6 rounded-lg shadow-inner mb-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Stock Levels by Product</h3>
          <Bar data={stockLevels} />
        </div>

        <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Inventory Turnover Over Time</h3>
          <Line data={turnoverData} />
        </div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default InventoryManagement;
