// Solutions.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import BoostSales from './BoostSales';
import OptimizePricing from './OptimizePricing';
import StreamlineInventory from './StreamlineInventory';
import EnhanceEfficiency from './EnhanceEfficiency';

const Solutions = () => {
  const [activeSection, setActiveSection] = useState('boost-sales');
  const { scrollY } = useViewportScroll();
  const navOpacity = useTransform(scrollY, [0, 100], [0, 1]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['boost-sales', 'optimize-pricing', 'streamline-inventory', 'enhance-efficiency'];
      const currentSection = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      if (currentSection) setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative">
      <motion.nav 
        className="fixed top-16 left-0 right-0 z-50 bg-white py-4 shadow-lg hidden md:block"
        style={{ opacity: navOpacity }}
      >
        <div className="container mx-auto flex justify-center flex-wrap gap-4">
          {['boost-sales', 'optimize-pricing', 'streamline-inventory', 'enhance-efficiency'].map((section, index) => (
            <Link
              key={section}
              to={section}
              smooth={true}
              duration={600}
              className={`cursor-pointer py-2 px-4 rounded-full transition-all duration-300 ${
                activeSection === section
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              {section.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </Link>
          ))}
        </div>
      </motion.nav>
      <div className="pt-32 md:pt-24">
        <BoostSales />
        <OptimizePricing />
        <StreamlineInventory />
        <EnhanceEfficiency />
      </div>
    </div>
  );
};

export default Solutions;