import React, { useState } from 'react';
import { FaWhatsapp, FaTelegramPlane, FaEnvelope, FaArrowCircleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import Modal from './Modal';

const Automation = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const navigate = useNavigate(); // Use navigate to programmatically navigate

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleWhatsAppAutomation = () => {
    navigate('/whatsapp-chat'); // Navigate to the WhatsAppStyleChat component
  };

  const renderWhatsAppAutomation = () => (
    <div>
      <p className="mb-4">Set up an automation to send WhatsApp notifications:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Message Content</label>
          <textarea className="w-full border-gray-300 rounded-md" placeholder="Enter the message content"></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Trigger Event</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>Sales Cross $10,000</option>
            <option>Inventory Drops Below 50 Units</option>
            <option>New Customer Sign-Up</option>
          </select>
        </div>
        <button
          type="button"
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          onClick={handleWhatsAppAutomation} // Call the function to navigate
        >
          Set Up Automation
        </button>
      </form>
    </div>
  );

  const renderTelegramAutomation = () => (
    <div>
      <p className="mb-4">Set up an automation to send Telegram messages:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Message Content</label>
          <textarea className="w-full border-gray-300 rounded-md" placeholder="Enter the message content"></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Trigger Event</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>Daily Sales Report</option>
            <option>Weekly Inventory Summary</option>
            <option>Monthly Customer Feedback</option>
          </select>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Set Up Automation
        </button>
      </form>
    </div>
  );

  const renderEmailAutomation = () => (
    <div>
      <p className="mb-4">Set up an automation to send email reports:</p>
      <form>
        <div className="mb-4">
          <label className="block text-gray-700">Subject</label>
          <input type="text" className="w-full border-gray-300 rounded-md" placeholder="Enter email subject" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Email Content</label>
          <textarea className="w-full border-gray-300 rounded-md" placeholder="Enter the email content"></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Trigger Event</label>
          <select className="w-full border-gray-300 rounded-md">
            <option>End of Day Sales Summary</option>
            <option>Weekly Performance Report</option>
            <option>Monthly Inventory Report</option>
          </select>
        </div>
        <button type="submit" className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600">
          Set Up Automation
        </button>
      </form>
    </div>
  );

  return (
    <DashboardLayout>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
          <FaArrowCircleRight className="text-green-500 mr-2" /> Automations
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div className="bg-green-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaWhatsapp className="text-green-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-green-800">WhatsApp Notifications</p>
              <button
                onClick={() => handleModalOpen('WhatsApp Automation', renderWhatsAppAutomation())}
                className="ml-2 mt-2 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
              >
                Set Up <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>

          <div className="bg-blue-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaTelegramPlane className="text-blue-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-blue-800">Telegram Messages</p>
              <button
                onClick={() => handleModalOpen('Telegram Automation', renderTelegramAutomation())}
                className="ml-2 mt-2 inline-block bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
              >
                Set Up <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>

          <div className="bg-purple-100 p-4 rounded-lg shadow-inner flex items-center">
            <FaEnvelope className="text-purple-500 text-3xl mr-4" />
            <div>
              <p className="text-lg font-semibold text-purple-800">Email Reports</p>
              <button
                onClick={() => handleModalOpen('Email Automation', renderEmailAutomation())}
                className="ml-2 mt-2 inline-block bg-purple-500 text-white px-3 py-1 rounded hover:bg-purple-600"
              >
                Set Up <FaArrowCircleRight className="inline ml-1" />
              </button>
            </div>
          </div>
        </div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </DashboardLayout>
  );
};

export default Automation;
