import React, { useState } from 'react';
import { FaChartLine, FaUsers, FaTags,
     FaBoxes, FaBell, FaUserCircle, FaBars, 
     FaDatabase, FaRobot, FaCogs, FaEnvelope, 
     FaWhatsapp, FaTelegramPlane, 
      FaChevronDown, FaChevronUp,
      FaHome
     } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import KPIContainer from './KPIContainer';
import KPI from './KPI'; // Import the KPI component
import ChatbotFloater from './ChatbotFloater';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isKPIBarCollapsed, setIsKPIBarCollapsed] = useState(false);

  // Simulated KPI data that would be passed to the Chatbot
  const kpiData = {
    sales: '+15%',
    inventory: 'Optimal',
    customerSatisfaction: '4.8/5',
    revenueForecast: '+12%',
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const toggleKPIBar = () => {
    setIsKPIBarCollapsed(!isKPIBarCollapsed);
  };

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <div className={`bg-gray-800 text-white fixed h-full transition-all duration-300 ${isSidebarOpen ? 'w-64' : 'w-16'}`}>
        <div className="p-4 flex items-center justify-between">
          <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-2xl font-semibold`}>JaanchAI</span>
          <button onClick={toggleSidebar} className="text-white focus:outline-none">
            <FaBars />
          </button>
        </div>
        <nav className="mt-8 space-y-2">
        <Link to="/dashboard" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaHome className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Dashboard</span>
          </Link>
          <Link to="/sales-analytics" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaChartLine className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Sales Analytics</span>
          </Link>
          <Link to="/customers-insights" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaUsers className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Customer Insights</span>
          </Link>
          <Link to="/pricingview" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaTags className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Pricing Strategies</span>
          </Link>
          <Link to="/inventory" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaBoxes className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Inventory Management</span>
          </Link>
          <Link to="/database" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaDatabase className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Database Connections</span>
          </Link>
          <Link to="/automations" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaRobot className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Automations</span>
          </Link>
          <div className="ml-4">
            <Link to="/automations/whatsapp" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
              <FaWhatsapp className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>WhatsApp</span>
            </Link>
            <Link to="/automations/email" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
              <FaEnvelope className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Email</span>
            </Link>
            <Link to="/automations/telegram" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
              <FaTelegramPlane className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Telegram</span>
            </Link>
          </div>
          <Link to="/settings" className="flex items-center py-2 px-4 text-lg hover:bg-gray-700">
            <FaCogs className="mr-4" /> <span className={`${isSidebarOpen ? 'block' : 'hidden'}`}>Settings</span>
          </Link>
        </nav>
      </div>

      {/* Main Content */}
      <div className={`flex-grow ${isSidebarOpen ? 'ml-64' : 'ml-16'} transition-all duration-300`}>
        {/* Navbar */}
        <header className="fixed top-0 left-0 right-0 bg-white p-4 shadow-md flex items-center justify-between z-10">
          <div className="flex items-center">
            <button onClick={toggleSidebar} className="text-gray-600 focus:outline-none mr-4">
              <FaBars />
            </button>
            <h1 className="text-2xl font-bold text-gray-800">JaanchAI</h1>
          </div>
          <div className="flex items-center space-x-4">
            <FaBell className="text-gray-600 text-2xl cursor-pointer hover:text-gray-800" />
            <div className="relative">
              <FaUserCircle className="text-gray-600 text-2xl cursor-pointer hover:text-gray-800" onClick={toggleProfileMenu} />
              {isProfileMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-10">
                  <Link to="/profile" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                    Profile
                  </Link>
                  <Link to="/settings" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                    Settings
                  </Link>
                  <Link to="/logout" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                    Logout
                  </Link>
                </div>
              )}
            </div>
          </div>
        </header>

        {/* KPI Bar */}
        <div className="mt-16 p-4 bg-gray-100 shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">KPIs</h2>
            <button onClick={toggleKPIBar} className="text-gray-600 focus:outline-none">
              {isKPIBarCollapsed ? <FaChevronDown /> : <FaChevronUp />}
            </button>
          </div>
          {!isKPIBarCollapsed && (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <KPI id={1} name="Sales" />
              <KPI id={2} name="Inventory" />
              <KPI id={3} name="Revenue" />
              <KPI id={4} name="Customer Satisfaction" />
              {/* Add more draggable KPIs as needed */}
            </div>
          )}
        </div>

        {/* Content */}
        <main className="p-8 flex-grow">
          <section id="kpi-container" className="bg-white rounded-lg shadow-md p-6 h-full">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">KPI Analysis</h2>
            <KPIContainer />
            <ChatbotFloater kpiData={kpiData} />
          </section>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
