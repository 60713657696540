import React from 'react';

const NoCodeImplementations = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">No Code Implementations</h2>
        <p className="text-lg mb-12 text-center text-gray-600 max-w-2xl mx-auto">
          Connect JaanchAI seamlessly with your existing platforms and business systems. Our no-code solution ensures you receive clear, actionable recommendations quickly, maximizing your return on investment.
        </p>
        <div className="flex justify-center mb-12">
          {/* Add any additional content or elements here if needed */}
        </div>
        <div className="text-center">
          <h3 className="text-2xl font-semibold mb-6 text-gray-800">Integrated Platforms</h3>
          <ul className="list-none grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 text-gray-700">
            {[
              { name: 'Salesforce', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/1200px-Salesforce.com_logo.svg.png' },
              { name: 'Microsoft Dynamics 365', logo: 'https://1000logos.net/wp-content/uploads/2021/12/Dynamics-365-logo.png' },
              { name: 'Shopify', logo: 'https://logosandtypes.com/wp-content/uploads/2020/11/Shopify.png' },
              { name: 'BigCommerce', logo: 'https://w7.pngwing.com/pngs/399/831/png-transparent-bigcommerce-hd-logo.png' },
              { name: 'WooCommerce', logo: 'https://woocommerce.com/wp-content/uploads/2023/08/woocommerce-01.png' },
            ].map(platform => (
              <li key={platform.name} className="flex flex-col items-center justify-center p-4 bg-white shadow-md rounded-lg">
                <img src={platform.logo} alt={platform.name} className="h-20 mb-2" />
                <span className="text-gray-800">{platform.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default NoCodeImplementations;
