import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaTags, FaArrowCircleRight } from 'react-icons/fa';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const OptimizePricing = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (title, content) => {
    setModalContent({ title, content });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderPricingStrategyCTA = () => (
    <div>
      <p className="mb-4">To optimize pricing and increase margins, explore the following strategies:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Implement dynamic pricing models based on real-time market trends.</li>
        <li>Segment customers by price sensitivity to offer targeted discounts.</li>
        <li>Use AI to predict optimal pricing for upcoming sales seasons.</li>
      </ul>
      <Link to="/request-demo" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 inline-block mt-4">
        Request Demo <FaArrowCircleRight className="inline ml-1" />
      </Link>
    </div>
  );

  const renderPromotionAnalysisCTA = () => (
    <div>
      <p className="mb-4">To analyze and optimize your promotional efforts, consider these actions:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Identify the promotions that yield the highest ROI.</li>
        <li>Reduce deep discounting while maintaining customer satisfaction.</li>
        <li>Leverage AI to predict the effectiveness of upcoming promotional campaigns.</li>
      </ul>
      <Link to="/request-demo" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 inline-block mt-4">
        Request Demo <FaArrowCircleRight className="inline ml-1" />
      </Link>
    </div>
  );

  return (
    <section id="optimize-pricing" className="py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl font-bold mb-8 text-center md:text-left">Smart Pricing & Promos</h2>
          <div className="flex flex-col md:flex-row items-center mb-8">
            <div className="md:w-1/3 mb-4 md:mb-0">
              <FaTags className="text-4xl text-green-500 mx-auto md:mx-0" />
            </div>
            <div className="md:w-2/3">
              <p className="text-lg text-center md:text-left">
                JaanchAI utilizes AI-powered pricing optimization and promotional effectiveness analysis to help you stay competitive and profitable. By continuously monitoring market trends, competitor pricing, and consumer demand, JaanchAI provides data-driven insights and recommendations for optimal pricing and promotional strategies.
              </p>
            </div>
          </div>
          <div className="bg-green-100 rounded-lg p-6 shadow-lg mb-6">
            <h3 className="text-xl font-bold mb-4">Home Appliance Retailer Example</h3>
            <p className="text-lg mb-4">
              Problem: A home appliance retailer was facing intense competition and struggling to maintain profitability. They often resorted to deep discounts to attract customers but failed to understand the impact of their promotional activities on overall profitability.
            </p>
            <p className="text-lg">
              Solution: JaanchAI analyzed the retailer's pricing and promotional data, considering factors such as competitor pricing, customer price sensitivity, and seasonal demand. By providing optimized pricing recommendations and identifying the most effective promotional strategies, JaanchAI helped the retailer increase gross margins by 15% while maintaining competitive prices. The retailer also saw a 25% improvement in promotional ROI, ensuring that their marketing efforts were driving profitable growth.
            </p>
            <button
              onClick={() => handleModalOpen('Optimize Pricing Strategy', renderPricingStrategyCTA())}
              className="ml-2 mt-4 inline-block bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
            >
              Optimize Pricing <FaArrowCircleRight className="inline ml-1" />
            </button>
            <button
              onClick={() => handleModalOpen('Analyze Promotions', renderPromotionAnalysisCTA())}
              className="ml-2 mt-4 inline-block bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
            >
              Analyze Promotions <FaArrowCircleRight className="inline ml-1" />
            </button>
          </div>
        </motion.div>

        {/* Modal Component */}
        <Modal show={showModal} onClose={handleModalClose} title={modalContent.title}>
          {modalContent.content}
        </Modal>
      </div>
    </section>
  );
};

export default OptimizePricing;
